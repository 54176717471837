<template>
	<div class="yinsifuwu-page">
		<div class="tab-box">
			<a-button :type="curTab == 1 ? 'primary' : ''" size="small" @click="changeTab(1)">用户协议</a-button>
			<a-button :type="curTab == 2 ? 'primary' : ''" size="small" @click="changeTab(2)">隐私声明</a-button>
		</div>
		<div class v-show="curTab == 1" v-html="content"></div>
		<div class v-show="curTab == 2" v-html="content1"></div>
	</div>
</template>

<script>
import axios from 'axios'
export default {
	data() {
		return {
			curTab: 1,
			content: '',
			content1: '',
		}
	},
	computed: {

	},
	created() {
		this.getInfo()
		this.getInfo2()
	},
	mounted() {

	},
	methods: {
		changeTab(ind) {
			this.curTab = ind
		},
		getInfo() {
			let t = this
			axios({
				url: 'https://yqbapi.zjgoho.com/api/wx/service',
				timeout: 5000,
				method: 'post'
			}).then(res => {
				let { code, data, msg } = res.data
				if (code == 0) {
					t.content = data.content
				} else {
					t.$message.error(msg, 1.5)
				}
			}).catch(err => {
				t.$message.error('网络连接失败', 1.5)
			})
		},
		getInfo2() {
			let t = this
			axios({
				url: 'https://yqbapi.zjgoho.com/api/wx/privacy',
				timeout: 5000,
				method: 'post'
			}).then(res => {
				let { code, data, msg } = res.data
				if (code == 0) {
					t.content1 = data.content
				} else {
					t.$message.error(msg, 1.5)
				}
			}).catch(err => {
				t.$message.error('网络连接失败', 1.5)
			})
		},
	}
}
</script>

<style lang="less">
.yinsifuwu-page {
	width: 92%;
	margin: 0 auto;
	padding-top: 60px;
}
.tab-box {
	display: flex;
	justify-content: center;
	background: #fff;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	left: 0%;
	top: 0;
	padding: 10px 0;
	border-bottom: 1px solid #e5e5e5;
	.ant-btn {
		margin-right: 20px;
		&:last-child {
			margin-right: 0;
		}
	}
}
</style>
